import React, { useState, useEffect, Suspense, useMemo } from 'react';
import {
  Menu,
  Header,
  Breadcrumb,
  Divider,
  Dimmer,
  Loader,
  Icon,
} from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import {
  StitchMembersProvider,
  useStitchMembers,
} from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import InputSection from './InputSection/InputSection';
import HeaderSection from './HeaderSection';
import { useGeneralParams } from '../GeneralParams';
import CallModal from '../TwilioPhoneCalls/CallModal';
import { numUnreadMessages } from '../Common/memberUtils';
import { platformServices } from '../Common/Values';

function NumTextMsg(props) {
  const { memberOne } = useStitchMembers();
  if (!memberOne || numUnreadMessages(memberOne.messageData) === 0) {
    return <span></span>;
  } else {
    return <span>({numUnreadMessages(memberOne.messageData)})</span>;
  }
}

export default function MemberDetails() {
  const { userCustomData } = useStitchAuth();
  const { listFilter } = useGeneralParams();
  const { memberId } = useParams();
  const pvStart = new Date().getTime();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const { memberOne, actions } = useStitchMembers();
 
  const groupServices =
    userCustomData && userCustomData.services
      ? userCustomData.services
      : [];
  const carePlanView = {
    pathname: 'care-plan',
    title: 'Care Plan',
    component: React.lazy(() => import('./CarePlan/CarePlan')),
    divider: true,
  };
  const viewList = [
    {
      pathname: 'profile',
      title: 'Profile',
      component: React.lazy(() => import('./Profile')),
      divider: false,
      icon: 'address card',
    },
    {
      pathname: 'measurements',
      title: 'Physiological Measurements',
      component: React.lazy(() =>
        import('./Measurements/Measurements'),
      ),
      divider: false,
      icon: 'heart',
    },
    {
      pathname: 'ecg-reports',
      title: 'ECG Reports (by AliveCor)',
      component: React.lazy(() => import('./ECGReports')),
      divider: false,
      icon: 'heartbeat',
    },
    {
      pathname: 'wellness-check',
      title: 'Wellness Check Questions',
      component: React.lazy(() => import('./WellnessCheck')),
      divider: false,
      icon: 'check',
    },
    {
      pathname: 'clinical-risk',
      title: 'Clinical Risk Models',
      component: React.lazy(() => import('./ClinicalRisk')),
      divider: false,
      icon: 'eye',
    },
    {
      pathname: 'billing-insights',
      title: 'Billing Insights',
      component: React.lazy(() =>
        import('./BillingInsights/BillingInsights'),
      ),
      divider: true,
      icon: 'dollar sign',
    },
    {
      pathname: 'telehealth',
      title: 'Telehealth',
      component: React.lazy(() => import('./Telehealth')),
      divider: false,
      icon: 'video',
    },
    {
      pathname: 'care-calendar',
      title: 'Care Calendar',
      component: React.lazy(() =>
        import('./CareCalendar/CareCalendar'),
      ),
      divider: false,
      icon: 'calendar',
    },
    {
      pathname: 'text-messages',
      title: 'Text Messages',
      component: React.lazy(() => import('./Messages')),
      divider: false,
      icon: 'chat',
    },
    {
      pathname: 'phone-calls',
      title: 'Phone Calls',
      component: React.lazy(() =>
        import('../TwilioPhoneCalls/PhoneLog'),
      ),
      divider: false,
      icon: 'phone',
    },
    {
      pathname: 'notes',
      title: 'Notes',
      component: React.lazy(() => import('./Notes')),
      divider: false,
      icon: 'sticky note',
    },
    {
      pathname: 'alert-doctor',
      title: 'Alert Doctor',
      component: React.lazy(() => import('./AlertDoctor')),
      divider: true,
      icon: 'user doctor',
    },
    {
      pathname: 'manage-rx-info',
      title: 'Manage Rx Info',
      component: React.lazy(() => import('./Medications')),
      divider: false,
      icon: 'pills',
    },
    {
      pathname: 'manage-dx-info',
      title: 'Manage Dx Info',
      component: React.lazy(() => import('./ManageDx')),
      divider: false,
      icon: 'stethoscope',
    },
    {
      pathname: 'manage-devices',
      title: 'Manage Devices',
      component: React.lazy(() => import('./Devices/Devices')),
      divider: false,
      icon: 'briefcase',
    },
    {
      pathname: 'manage-rewards',
      title: 'Manage Rewards',
      component: React.lazy(() => import('./Rewards/Rewards')),
      divider: false,
      icon: 'gift',
    },
    {
      pathname: 'manage-rawdata',
      title: 'Manage Raw Data',
      component: React.lazy(() => import('./Rawdata/Rawdata')),
      divider: false,
      icon: 'database',
    },
    {
      pathname: 'em-visits',
      title: 'E/M Visits',
      component: React.lazy(() => import('./EmVisits')),
      divider: false,
      icon: 'hospital',
    },
    {
      pathname: 'manage-time',
      title: 'Manage Time',
      component: React.lazy(() => import('./TimeManagement')),
      divider: false,
      icon: 'clock',
    },
    {
      pathname: 'edit-profile',
      title: 'Edit Profile',
      component: React.lazy(() =>
        import('./EditProfile/EditProfile'),
      ),
      divider: false,
      icon: 'edit',
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (userCustomData) {
      actions.loadMember(memberId);
      setLoading(false);
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId, userCustomData]);

  if (groupServices.includes(platformServices.CCM)) {
    viewList.splice(6, 0, carePlanView);
  }

  const shouldIncludeView = (view) => {
    if (['manage-rawdata', 'measurements'].includes(view.pathname)) {
      return !memberOne?.disenrolled;
    }
    return true;
  };

  const filteredViewList = useMemo(() => {
    return viewList.filter(view => shouldIncludeView(view));
  }, [viewList, memberOne]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <StitchMembersProvider>
        <>
          <CallModal />
          {/* The call modal lives here to it persists as menus are changed. */}
          <div className="app-layout">
            <AppBar />
            <Workspace />
            <div className="channels box">
              <Breadcrumb
                style={{ paddingLeft: '1em', fontSize: '0.8em' }}
              >
                <Breadcrumb.Section
                  link
                  style={{ color: '#fff' }}
                  onClick={() =>
                    history.push({
                      pathname: '/home/' + (listFilter || ''),
                    })
                  }
                >
                  {listFilter}
                </Breadcrumb.Section>
                <Breadcrumb.Divider
                  icon="right angle"
                  style={{ color: '#fff' }}
                />
                <Breadcrumb.Section style={{ color: '#fff' }}>
                  member-details
                </Breadcrumb.Section>
              </Breadcrumb>
              <Header
                as="h4"
                style={{ paddingLeft: '1em', color: '#fff' }}
              >
                Member Details
              </Header>
              <Menu
                vertical
                secondary
                inverted
                style={{ background: 'transparent' }}
              >
                {filteredViewList.map((view) => (
                  <span key={view.pathname}>
                    <Menu.Item
                      active={
                        location.pathname ===
                        `/member-details/${memberId}/${view.pathname}`
                      }
                    >
                      <Link to={`${url}/${view.pathname}`}>
                        <Icon
                          name={view.icon || 'bookmark'}
                          style={{
                            float: 'left',
                            marginRight: '10px',
                          }}
                        />
                        {view.pathname}
                      </Link>
                      {view.pathname === 'text-messages' && (
                        <NumTextMsg />
                      )}
                    </Menu.Item>
                    {view.divider && <Divider />}
                  </span>
                ))}
              </Menu>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {filteredViewList.map((view) => (
                  <Route
                    key={view.pathname}
                    path={`${path}/${view.pathname}`}
                  >
                    <HeaderSection
                      memberId={memberId}
                      title={view.title}
                      pvStart={pvStart}
                      url={view.pathname}
                    />
                    <view.component memberId={memberId} />
                    <InputSection pathname={view.pathname} />
                  </Route>
                ))}
              </Switch>
            </Suspense>
          </div>
        </>
      </StitchMembersProvider>
    );
  }
}
