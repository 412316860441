import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStitchAuth } from '../../StitchAuth';
import { Input, Search } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import CallNotification from './CallNotification';
import { useStitchMembers } from '../../StitchMembers';
import { isSuperAdmin } from '../memberUtils';

const TopBar = styled.div`
  display: flex;
`;

const LogoFixedWidth = styled.div`
  width: 184px;
`;

export default function AppBar(props) {
  const {
    userCustomData,
    invoices,
    actions,
    db: { members },
  } = useStitchAuth();
  const {actions: actionsMember} = useStitchMembers();
  let history = useHistory();
  let location = useLocation();
  const clientCode = 'orma';
  const [searchUniverse, setSearchUniverse] = useState([]);
  const [searchResults, setSearchResults] = useState(searchUniverse);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [numOpenInvoices, setNumOpenInvoices] = useState(0);
  const hasSuperAdminAccess =  isSuperAdmin(userCustomData);

  const menuItems = [
    {
      pathMatches: ['home', 'member', 'member-details'],
      to: '/home/all-members',
      title: 'Members',
    },
    {
      pathMatches: ['prospects', 'prospect-details'],
      to: '/prospects',
      title: 'Prospects',
    },
    {
      pathMatches: ['devices'],
      to: '/devices/fulfillment',
      title: 'Devices',
    },
    {
      pathMatches: ['reports'],
      to: '/reports/dashboard',
      title: 'Reports',
    },
    {
      pathMatches: ['enrollment'],
      to: '/enrollment/member',
      title: 'Enroll',
    },
    {
      pathMatches: ['settings'],
      to: '/settings/user',
      title: 'Settings',
    },
    {
      pathMatches: ['admin'],
      to: '/admin',
      title: 'Admin',
      onlyAdminAcess: true
    }
  ];

  const handleResultSelect = (e, { result }) => {
    history.push(result.url);
  };

  const handleSearchChange = (e, { value }) => {
    setIsLoading(true);
    setSearchTerm(value);
    //setTimeout(() => {
    const re = new RegExp(_.escapeRegExp(value), 'i');
    const isMatch = (result) => re.test(result.title);
    setSearchResults(searchUniverse.filter(isMatch).slice(0, 10));
    setIsLoading(false);
    //}, 300)
  };

  useEffect(() => {
    if (invoices) {
      setNumOpenInvoices(
        invoices.filter((x) => x.status === 'open').length,
      );
    }
  }, [invoices]);

  useEffect(() => {
    let isSubscribed = true;

    if (!userCustomData) {
      actions.handleRefreshCustomData(clientCode);
    } else {
      const query = {
        $or: [
          { disenrolled: false },
          { disenrolled: { $exists: false } },
        ],
      };

      const projection = {
        _id: { $toString: '$_id' },
        owner_id: 1,
        name: 1,
        phone: 1,
        email: 1,
        dob: 1,
      };
      //here
      actionsMember.
      fetchMembersByQuery(query, projection) 
        .then((data) => {
          if (isSubscribed) {
            setSearchUniverse(
              _.map(data, (d) => {
                return {
                  id: d._id,
                  title:
                    d.name +
                    ', ' +
                    d.dob.substring(5, 7) +
                    '/' +
                    d.dob.substring(8, 10) +
                    '/' +
                    d.dob.substring(0, 4),
                  description:
                    'phone: ' + d.phone + ', email: ' + d.email,
                  url: `/member-details/${d._id}/profile`,
                };
              }),
            );
          }
        });
    }

    return () => (isSubscribed = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, members]);

  useEffect(() => {
    if (userCustomData && !userCustomData.hasBasicInformation) {
      history.replace('/userinformation');
    }
  }, [userCustomData, history]);

  const logout = (e) => {
    e.preventDefault();
    actions.handleLogout();
  };

  const hasAccess = (menuItem) => {
    return !menuItem.onlyAdminAcess || hasSuperAdminAccess;
  };

  if (userCustomData && !userCustomData.hasBasicInformation) {
    return (
      <div className="appbar box">
        <Link to="/home/all-members">
          <img
            alt="logo"
            src="/logos/astranalogo-white-2.png"
            style={{
              height: '30px',
              float: 'left',
              paddingLeft: '1em',
            }}
          />
        </Link>
        <ul style={{ float: 'right', listStyle: 'none', margin: 0 }}>
          <li style={{ float: 'left', marginLeft: '1em' }}>
            <Link to="/" onClick={logout} style={{ color: '#fff' }}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div className="appbar box">
        <TopBar>
          <LogoFixedWidth>
            <Link to="/home/all-members">
              <img
                alt="logo"
                src="/logos/astranalogo-white-2.png"
                style={{
                  height: '30px',
                  float: 'left',
                  paddingLeft: '1em',
                }}
              />
            </Link>
          </LogoFixedWidth>
          <div style={{ width: '300px' }}>
            {false && (
              <Input
                size="mini"
                icon="search"
                iconPosition="left"
                placeholder="Search a member..."
                className="search-box"
              />
            )}
            <Search
              size="mini"
              className="search-box"
              placeholder="Search a member by name"
              input={{ icon: 'search', iconPosition: 'left' }}
              loading={isLoading}
              onResultSelect={handleResultSelect}
              onSearchChange={_.debounce(handleSearchChange, 500, {
                leading: true,
              })}
              results={searchResults}
              value={searchTerm}
            />
          </div>
          <CallNotification />
          {menuItems
            .filter(menuItem => hasAccess(menuItem))
            .map((menuItem) => (
              <div
                key={menuItem.to}
                style={{
                  marginLeft:
                    menuItem.title === 'Members' ? 'auto' : '1em',
                }}
              >
                <Link
                  to={menuItem.to}
                  style={{
                    color: '#bdc9e8',
                    height: '30px',
                    lineHeight: '30px',
                  }}
                >
                  <span
                    className={
                      menuItem.pathMatches.indexOf(
                        location.pathname.split('/')[1],
                      ) > -1
                        ? 'underline'
                        : ''
                    }
                  >
                    {menuItem.title}
                  </span>
                  {menuItem.title === 'Reports' && numOpenInvoices > 0 && (
                    <sup
                      style={{
                        verticalAlign: 'super',
                        fontSize: '0.5em',
                        position: 'relative',
                        top: '-0.3em',
                      }}
                    >
                      ({numOpenInvoices})
                    </sup>
                  )}
                </Link>
              </div>
            ))
          }
          <div style={{ marginLeft: '1em' }}>
            <Link
              to="/login"
              onClick={logout}
              style={{
                color: '#bdc9e8',
                height: '30px',
                lineHeight: '30px',
              }}
            >
              Logout
            </Link>
          </div>
        </TopBar>
      </div>
    );
  }
}
