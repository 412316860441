import React, { Suspense, useEffect } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import '../Common/Layout.css';
import AppBar from '../Common/AppBar/AppBar';
import '../Common/Layout.css';
import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useStitchAuth } from '../StitchAuth';
import { isSuperAdmin } from '../Common/memberUtils';

export default function Admin() {
  let history = useHistory();
  const {userCustomData} = useStitchAuth();
  const pvStart = new Date().getTime();
  //const [view, setView] = useState(viewDefault || 'user');
  let { path, url } = useRouteMatch();
  const viewList = [
    { pathname: 'manage-users', title: 'Manage Users' },
    { pathname: 'manage-workspaces', title: 'Manage Workspaces' },
    { pathname: 'add-user', title: 'Add User' },
    { pathname: 'setup-contract', title: 'Setup Contract' },
  ];
  const location = useLocation();
  const AddUser = React.lazy(() => import('./AddUser/AddUser'));
  const ManageUsers = React.lazy(() => import('./ManageUsers/ManageUsers'));
  const ManageWorkspaces = React.lazy(() => import('./ManageWorkspaces/ManageWorkspaces'));
  const SetupContract = React.lazy(() => import('./SetupContract/SetupContract'));

  useEffect(() => {
    if (userCustomData &&  !isSuperAdmin(userCustomData)) {
      history.replace('/home')
    }  
  }, [userCustomData, history]);

  return (
    <div className="app-layout">
      <AppBar />
      <div className="channels box">
        <Header as="h4" style={{ paddingLeft: '1em', color: '#fff' }}>
          Admin Panel
        </Header>
        <Menu
          vertical
          secondary
          inverted
          style={{ background: 'transparent' }}
        >
          {viewList.map((view) => (
            <Menu.Item
              key={view.pathname}
              active={
                location.pathname === `/admin/${view.pathname}`
              }
            >
              <Link to={`${url}/${view.pathname}`}>
                {`#  ${view.pathname}`}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="header-panel box">
        <Header as="h3">
          {
            (
              viewList.find(
                (view) =>
                  location.pathname === `/admin/${view.pathname}`,
              ) || {}
            ).title
          }
          <Header.Subheader>
            <small>
              last updated at {new Date(pvStart).toLocaleString()}
            </small>
          </Header.Subheader>
        </Header>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={{ pathname: `${path}/manage-users` }} />
          </Route>
          <Route path={`${path}/manage-users`}>
            <ManageUsers />
          </Route>
          <Route path={`${path}/manage-workspaces`}>
            <ManageWorkspaces />
          </Route>
          <Route path={`${path}/add-user`}>
            <AddUser />
          </Route>
          <Route path={`${path}/setup-contract`}>
            <SetupContract />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
}
