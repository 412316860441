import React, { useImperativeHandle } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DownloadNotes = React.forwardRef(({ member }, ref) => {
  useImperativeHandle(ref, () => ({
    exportToPDF: () => {
      const container = createContainer();
      const logo = createLogo();
      container.appendChild(logo);

      if (member) {
        const memberHeader = createMemberHeader(member.name);
        container.appendChild(memberHeader);
      }

      const groupedNotes = groupNotesByDate(member.noteData);
      addNotesToContainer(groupedNotes, container);

      document.body.appendChild(container);

      html2canvas(container, { scale: 1, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.7);
        const pdf = new jsPDF();
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'jpeg', 10, position, imgWidth, imgHeight);
        heightLeft -= pdf.internal.pageSize.height;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'jpeg', 10, position, imgWidth, imgHeight);
          heightLeft -= pdf.internal.pageSize.height;
        }

        pdf.save(`${member.name} - notes.pdf`);
        document.body.removeChild(container);
      });
    },
  }));

  const createContainer = () => {
    const container = document.createElement('div');
    container.style.padding = '24px';
    container.style.fontFamily = 'Arial, sans-serif';
    container.style.lineHeight = '1.6';
    container.style.color = '#333';
    return container;
  };

  const createLogo = () => {
    const logo = document.createElement('img');
    logo.src = '/logos/astranalogo.png'; // Update with your image path
    logo.style.width = '180px';
    logo.style.marginBottom = '24px';
    return logo;
  };

  const createMemberHeader = (name) => {
    const memberHeader = document.createElement('div');
    memberHeader.style.marginBottom = '32px';
    memberHeader.innerHTML = `
      <h1 style="color: #222; font-size: 36px; margin: 0;">${name} > Notes</h1>
    `;
    return memberHeader;
  };

  const groupNotesByDate = (notes) => {
    const sortedNotes = notes.sort((a, b) => new Date(b.time) - new Date(a.time));
    return sortedNotes.reduce((acc, note) => {
      const date = new Date(note.time).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(note);
      return acc;
    }, {});
  };

  const addNotesToContainer = (groupedNotes, container) => {
    Object.keys(groupedNotes).forEach(date => {
      const dateHeader = document.createElement('h2');
      dateHeader.style.marginTop = '24px';
      dateHeader.style.color = '#444';
      dateHeader.style.fontSize = '28px';
      dateHeader.innerText = date;
      container.appendChild(dateHeader);

      groupedNotes[date].forEach(note => {
        const noteDiv = document.createElement('div');
        noteDiv.style.marginBottom = '24px';
        noteDiv.style.border = '1px solid #ccc';
        noteDiv.style.padding = '16px';
        noteDiv.style.borderRadius = '5px';
        noteDiv.style.backgroundColor = '#f9f9f9';

        noteDiv.innerHTML = `
          <strong style="font-size: 32px;">${note.by}</strong><br/>
          <small style="color: #666; font-size: 28px;">${new Date(note.time).toLocaleTimeString()}</small><br/>
          <div style="margin: 6px 0; font-size: 32px;">${note.value}</div>
        `;
        container.appendChild(noteDiv);
      });
    });
  };

  return null; // No UI rendering
});

export default DownloadNotes;
