import React, { useEffect, useState, useCallback } from 'react';
import { useStitchAuth } from '../StitchAuth';
import { List, Button, Loader, Dimmer } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useGeneralParams } from '../GeneralParams';
import WorkspaceSearch from './WorkspaceSearch';

export default function Workspace(props) {
  const { userCustomData, actions } = useStitchAuth();
  const { setShowParam, setSortParam, setListFilter } =
    useGeneralParams();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState(userCustomData ? userCustomData.workspaces : []);
  const [searchText, setSearchText] = useState('');
  let history = useHistory();

  const handleWorkspaceChange = async (group_id) => {
    setLoading(true);
    await actions.handleWorkspaceChange(group_id);
    setLoading(false);
    setListFilter('all-members');
    setSortParam('measureDateDesc');
    setShowParam('all');
    setIsOpen(false);
    history.push({
      pathname: '/home/all-members',
    });
  };

  const handleSearchChange = (e, { value }) => {
    setSearchText(value);
    
    const re = new RegExp(_.escapeRegExp(value), 'i');
    const isMatch = (result) => re.test(result.code) || re.test(result.workspaceName);
    setWorkspaces(userCustomData.workspaces.filter(isMatch));
  };

  const resetSearch = useCallback(() => {
    setSearchText('');
    if (userCustomData?.workspaces) {
      setWorkspaces(userCustomData.workspaces);
    }
  }, [userCustomData]);

  useEffect(() => {
    if (userCustomData?.workspaces) {
      setWorkspaces(userCustomData.workspaces);
    }
  }, [userCustomData]);

  useEffect(() => {
    if (!isOpen) {
      resetSearch();
    }
  }, [isOpen, resetSearch])

  if (
    userCustomData &&
    userCustomData.workspaceAccess &&
    !userCustomData.superUserAccess
  ) {
    return (
      <div className="workspace box-small">
        <WorkspaceSearch 
          isOpen={isOpen} 
          setIsOpen={setIsOpen} 
          searchText={searchText}
          handleSearchChange={handleSearchChange}
        />
        <List>
          {workspaces.length ? 
            workspaces.map(x => {
              return (
                <List.Item key={x.group_id}>
                  <Button
                    inverted
                    size="mini"
                    onClick={() => handleWorkspaceChange(x.group_id)}
                    style={{
                      padding: '0.5em',
                      margin: '1px 1px 10px',
                      width: isOpen ? '100%': '40px',
                      height: '40px',
                    }}
                    active={x.group_id === userCustomData.group_id}
                    data-tip={x.workspaceName || x.code}
                  >
                    {isOpen ? x.workspaceName : x.code}
                  </Button>
                </List.Item>
              );
            }) : 
            <p style={{textAlign: "center"}}>No Result found</p>
          }

        </List>
        {!isOpen && <ReactTooltip place="right" type="light" effect="solid" />}
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Switching Workspace</Loader>
          </Dimmer>
        )}
      </div>
    );
  } else {
    return <div className="workspace"></div>;
  }
}
