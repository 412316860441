import React from 'react';
import PropTypes from 'prop-types';
import {
  hasLoggedInUser,
  logoutCurrentUser,
  getCurrentUser,
  getCustomData,
  signupUser,
  loginUser,
  sendResetPasswordEmail,
  resetPassword,
  confirmEmail,
} from './../realm/authentication';
//import { users, groups, invoices, } from "./../realm";
import { getDefaultClientCode } from './Common/whitelabel';
import { defaultErrorMessage } from './Common/Values';
//import rules from './rules.json';
//import smsTemplates from './smsTemplates.json';

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchAuthContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchAuth() {
  const context = React.useContext(StitchAuthContext);
  if (!context) {
    throw new Error(
      `useStitchAuth must be used within a StitchAuthProvider`,
    );
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function StitchAuthProvider(props) {
  const [authState, setAuthState] = React.useState({
    isLoggedIn: hasLoggedInUser(),
    currentUser: getCurrentUser(),
    userCustomData: null,
    clientName: getDefaultClientCode(),
    otherUsers: [],
    invoices: [],
    db: {
      members: null,
      prospects: null,
      pageviews: null,
      users: null,
      groups: null,
      registry: null,
      ecgs: null,
      billingreports: null,
      devicereqs: null,
      invoices: null,
      doctoralerts: null,
      twiliocalls: null,
      receivedcalls: null,
      tenovidevices: null,
    },
  });

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    // Authentication Actions
    const handleLogout = async () => {
      const { isLoggedIn } = authState;
      if (isLoggedIn) {
        await logoutCurrentUser();
        setAuthState({
          ...authState,
          isLoggedIn: false,
          currentUser: null,
          userCustomData: null,
          otherUsers: [],
        });
      } else {
        console.log(`can't handleLogout when no user is logged in`);
      }
    };

    const handleSignup = async (email, password) => {
      await signupUser(email, password);
    };

    /*
    // User sign up will be at the backend
    const handleUserAdd = async (
      name,
      namePublic,
      idx,
      userCustomData,
    ) => {
      
      const customData = {
        name: name,
        namePublic: namePublic,
        timeZone: userCustomData.timeZone,
        role: 'user', // this is a legacy field
        group_id: userCustomData.group_id, // this will be re-written at the backend
        id: authState.currentUser.id + '::' + idx,
        emailNotifications: {
          periodicAP: false,
          periodicMP: false,
          realtimeAP: false,
          realtimeMP: false,
        },
        email: 'not.available',
      };
      await authState.db.users.insertOne(customData);
      
    };
    */

    const handleUserDelete = async (data) => {
      try {
        let recipient = [
          `gdiaz@apollomed.net`,
          `epark@networkmedicalmanagement.com`,
          `jchiang@networkmedicalmanagement.com`,
        ];
        let emailBody = `<h3><strong>IMPORTANT MESSAGE:</strong> A User has been recently deleted by ${userCustomData.name}.</h3><br>
      <table style="width:100%;text-align:center">
        <tr>
          <th style="border-width:thin;border-style:solid;border-collapse:collapse;">User Name</th>
          <th style="border-width:thin;border-style:solid;border-collapse:collapse;">User ID</th>
          <th style="border-width:thin;border-style:solid;border-collapse:collapse;">User Email</th>
        </tr>
        <tr style="border-width:thin;border-style:solid;">
          <td>${data.name}</td>
          <td>${data.id}</td>
          <td>${data.email}</td>
        </tr>
      </table>
      <h3>
      User needs to be disabled from REALM.
      <br>Thanks!<br><br>Orma Team</h3>`;

        /*
        await authState.db.updateOne(
          { id: data.id },
          {
            $set: {
              old_group_id: data.group_id,
              group_id: 'DELETED',
            },
          },
        );

        currentUser.functions.sendEmailAws(
          'Orma Health <info@ormahealth.com>',
          recipient,
          '[Orma RPM] User to be disabled',
          emailBody,
        );
        return true;
        */
      } catch {
        return false;
      }
    };

    const addUserTitle = async (newUser) => {
      /*
      try {
        await authState.db.users.updateOne(
          { id: newUser.userId },
          {
            $set: {
              title: newUser.title,
            },
          },
        );
        return true;
      } catch {
        return false;
      }*/
    };

    const updateUser = async (user, data) => {
      let updateRes = authState.currentUser.functions.editUser(data, user.id);
      return updateRes;
    }

    const handleAddUser = async (newUserData) => {
      try {
        const { email, password } = newUserData;
        delete newUserData.password;
    
        // Sign up the user in realm
        let signupResponse = await signupUser(email, password);
        if (signupResponse?.error) {
          return defaultErrorMessage(signupResponse.error);
        }
        
        // Log in the user in realm to get realm id
        const user = await loginUser(email, password);
        if (!user) {
          return defaultErrorMessage;
        }
    
        // Prepare user data for insertion
        const userData = {
          ...newUserData,
          id: user.id,
          role: "user",
          timeZone: userCustomData.timeZone,
          workspaceAccess: true,
          hasBasicInformation: true,
          emailNotifications: {
            periodicAP: true,
            periodicMP: false,
            realtimeAP: false,
            realtimeMP: false,
          },
        };

        // Insert new user
        return await authState.currentUser.functions.insertNewUser(userData);
      } catch (err) {
        if (err.errorCode && err.errorCode == "AccountNameInUse") {
          return defaultErrorMessage("A user with this email already exists");
        }
        return defaultErrorMessage;
      }
    };
    
    /*
        // User sign up will be at the backend
    const handleUserSignup = async (
      email,
      password,
      name,
      namePublic,
      userCustomData,
      myPassword,
      userTitle,
    ) => {
      
      try {
        await loginUser(userCustomData.email, myPassword);
        await signupUser(email, password);
        //const loggedInUser = await loginUser(email, password);
        const customData = {
          name: name,
          namePublic: namePublic,
          timeZone: userCustomData.timeZone,
          //role: 'user', // this field is not relevant anymore
          //group_id: userCustomData.group_id, // this should be set at the back end
          //id: loggedInUser.id, // this field will be set at the backedn
          emailNotifications: {
            periodicAP: true,
            periodicMP: false,
            realtimeAP: false,
            realtimeMP: false,
          },
          email: email,
        };
        // NOTE: only for Iris Health
        if (
          userCustomData.group_id === 'lindaw@irishealthgroup.com'
        ) {
          customData.workspaces = userCustomData.workspaces;
          customData.workspaceAccess = userCustomData.workspaceAccess;
          customData.superUserAccess = userCustomData.superUserAccess;
        }
        // NOTE: only for CCM Members
        if (userTitle) customData.title = userTitle;

        await authState.db.users.insertOne(customData);
        await loginUser(userCustomData.email, myPassword);
        return true;
      } catch {
        return false;
      }
      
    };
    */

    const handleConfirmEmail = async (token, tokenId) => {
      confirmEmail(token, tokenId);
    };

    const handleEmailPasswordLogin = async (email, password) => {
      const loggedInUser = await loginUser(email, password);

      if (loggedInUser.customData.disabled) {
        return false;
      }
      setAuthState({
        ...authState,
        isLoggedIn: true,
        currentUser: loggedInUser,
        db: {
          members: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('members'),
          prospects: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('prospects'),
          pageviews: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('pageviews'),
          users: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('users'),
          groups: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('groups'),
          registry: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('registry'),
          ecgs: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('ecgs'),
          billingreports: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('billingreports'),
          devicereqs: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('devicereqs'),
          invoices: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('invoices'),
          doctoralerts: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('doctoralerts'),
          twiliocalls: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('twiliocalls'),
          receivedcalls: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('receivedcalls'),
          tenovidevices: loggedInUser
            .mongoClient('mongodb-atlas-test')
            .db('test')
            .collection('tenovidevices'),
        },
      });
      return true;
    };

    const handleResetPasswordSend = async (email) => {
      sendResetPasswordEmail(email);
    };

    const handleResetPassword = async (
      token,
      tokenId,
      newPassword,
    ) => {
      resetPassword(token, tokenId, newPassword);
    };

    const handleRefreshCustomData = async (clientCode) => {
      const customData = await getCustomData(clientCode);
      if (customData) {
        const otherUsers = await getOtherUsers(customData.group_id);
        const invoices = await getInvoices(customData.group_id);

        setAuthState({
          ...authState,
          userCustomData: customData,
          clientName: customData.clientName
            ? customData.clientName
            : 'orma',
          otherUsers: otherUsers,
          invoices: invoices,
          db: {
            members: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('members'),
            prospects: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('prospects'),
            pageviews: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('pageviews'),
            users: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('users'),
            groups: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('groups'),
            registry: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('registry'),
            ecgs: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('ecgs'),
            billingreports: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('billingreports'),
            devicereqs: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('devicereqs'),
            invoices: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('invoices'),
            doctoralerts: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('doctoralerts'),
            twiliocalls: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('twiliocalls'),
            receivedcalls: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('receivedcalls'),
            tenovidevices: authState.currentUser
              .mongoClient('mongodb-atlas-test')
              .db('test')
              .collection('tenovidevices'),
          },
        });
      }
    };

    const updateCustomData = async (customData) => {
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          ...customData,
        },
        clientName: customData.clientName
          ? customData.clientName
          : 'orma',
      });
      await authState.currentUser.functions.editUser(customData);
    };

    const updateClinicalRules = async (key, value) => {
      await authState.db.groups.findOneAndUpdate(
        { group_id: authState.userCustomData.group_id },
        { $set: { ['clinical_rules.' + key]: value } },
      );
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          clinical_rules: {
            ...authState.userCustomData.clinical_rules,
            [key]: value,
          },
        },
      });
    };

    const updateBillingConfig = async (billingConfig) => {
      await authState.db.groups.findOneAndUpdate(
        { group_id: authState.userCustomData.group_id },
        { $set: { billingConfig: { ...billingConfig } } },
      );
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          billingConfig: { ...billingConfig },
        },
      });
    };

    const updateShowLiveBilling = async (newValue) => {
      await authState.db.groups.findOneAndUpdate(
        { group_id: authState.userCustomData.group_id },
        { $set: { showLiveBilling: newValue } },
      );
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          showLiveBilling: newValue,
        },
      });
    };

    const updateSMSTemplates = async (newSMSTemplates) => {
      await authState.db.groups.findOneAndUpdate(
        { group_id: authState.userCustomData.group_id },
        { $set: { smsTemplates: { ...newSMSTemplates } } },
      );
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          smsTemplates: { ...newSMSTemplates },
        },
      });
    };

    const updateConsents = async (consents) => {
      await authState.db.groups.findOneAndUpdate(
        { group_id: authState.userCustomData.group_id },
        { $set: consents },
      );
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          ...consents,
        },
      });
    };

    const updateAgreement = async (agreement) => {
      await authState.db.groups.findOneAndUpdate(
        { group_id: authState.userCustomData.group_id },
        { $set: agreement },
      );
      await authState.currentUser.functions.editUser({
        name: agreement.agreement.contactName,
        namePublic: agreement.agreement.contactName,
      });
      /*await authState.db.users.findOneAndUpdate(
        { id: authState.currentUser.id },
        {
          $set: {
            name: agreement.agreement.contactName,
            namePublic: agreement.agreement.contactName,
          },
        },
      );*/

      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          ...agreement,
          name: agreement.agreement.contactName,
          namePublic: agreement.agreement.contactName,
        },
      });
    };

    const getOtherUsers = async (groupId) => {
      const usersDb = await authState.currentUser
        .mongoClient('mongodb-atlas-test')
        .db('test')
        .collection('users');

      const currentUserDoc = await usersDb.findOne({
        id: authState.currentUser.id,
      });

      // only for super user environments (Iris Health, CVMG)
      if (currentUserDoc.superUserAccess) {
        const workspacesArr = currentUserDoc.workspaces.map(
          (workspace) => workspace.group_id,
        );
        return await usersDb.find({
          group_id: { $in: workspacesArr },
        });
      } else {
        return await usersDb.find({ group_id: groupId });
      }
    };

    const getInvoices = async (groupID) => {
      return await authState.currentUser
        .mongoClient('mongodb-atlas-test')
        .db('test')
        .collection('invoices')
        .find({ 'metadata.group_id': groupID });
    };

    const fetchUsers = async () => {
      let usersData = await currentUser.functions.fetchUsers();
      return usersData;
    }

    const fetchGroups = async () => {
      let groupsData = await currentUser.functions.fetchGroups();
      return groupsData;
    }

    const handleWorkspaceChange = async (groupIdNew) => {
      await authState.currentUser.functions.editUser({
        group_id: groupIdNew,
      });
      await handleRefreshCustomData();
    };

    const handleEmailNotifications = async (value) => {
      await authState.currentUser.functions.editUser({
        emailNotifications: value,
      });
      setAuthState({
        ...authState,
        userCustomData: {
          ...authState.userCustomData,
          emailNotifications: value,
        },
      });
    };

    const updateExcludePatientTime = async (uid, newVal) => {
      /*
      // TODO: this needs a backend function
      await authState.db.users.updateOne(
        { id: uid },
        { $set: { excludePatientTime: newVal } },
      );
      */
    };

    const {
      isLoggedIn,
      currentUser,
      userCustomData,
      clientName,
      otherUsers,
      invoices,
      db,
    } = authState;
    const value = {
      isLoggedIn,
      currentUser,
      userCustomData,
      clientName,
      otherUsers,
      invoices,
      db,
      actions: {
        handleLogout,
        handleSignup,
        handleConfirmEmail,
        handleEmailPasswordLogin,
        handleResetPasswordSend,
        handleResetPassword,
        handleRefreshCustomData,
        updateCustomData,
        updateClinicalRules,
        updateBillingConfig,
        updateShowLiveBilling,
        updateSMSTemplates,
        updateConsents,
        updateAgreement,
        getOtherUsers,
        fetchUsers,
        fetchGroups,
        //handleUserSignup,
        //handleUserAdd,
        handleAddUser,
        handleWorkspaceChange,
        handleEmailNotifications,
        updateExcludePatientTime,
        handleUserDelete,
        addUserTitle,
        updateUser
      },
    };
    return value;
  }, [authState]);
  return (
    <StitchAuthContext.Provider value={authInfo}>
      {props.children}
    </StitchAuthContext.Provider>
  );
}
StitchAuthProvider.propTypes = {
  children: PropTypes.element,
};
