import React, { useState, useEffect, useCallback } from 'react';
import { Form, Message } from 'semantic-ui-react';
import BloodGlucoseDevice from './BloodGlucoseDevice';
import BloodPressureDevice from './BloodPressureDevice';
import WeightScaleDevice from './WeightScaleDevice';
import TenoviWeightScale from './TenoviWeightScale';
import TenoviBloodPressure from './TenoviBloodPressure';
import TenoviGlucometer from './TenoviGlucometer';
import TenoviBloodPressureXL from './TenoviBloodPressureXL';

const DevicesSection = ({
  formError,
  setFormError,
  bt004,
  bt105,
  smig,
  bt004Imei,
  bt105Imei,
  smigImei,
  setBt004,
  setBt105,
  setSmig,
  setBt004Imei,
  setBt105Imei,
  setSmigImei,
  setSendEnrollmentText,
  tenoviDeviceTypes,
  setTenoviDeviceTypes,
  tenoviGatewayId,
  setTenoviGatewayId,
  setIsRequestLimitExceeded,
  tenoviSelection,
  setTenoviSelection
}) => {
  const [bodyTraceSelection, setBodyTraceSelection] = useState(false);
  let checkAlphaNumbericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;

  const countRequestedDevices = useCallback(() => {
    let count = 0;
    if (bodyTraceSelection) {
      if (bt004 === 'request') count++;
      if (bt105 === 'request') count++;
      if (smig === 'request') count++;
    }
    if (tenoviSelection) {
      Object.values(tenoviDeviceTypes).forEach(device => {
        if (device.state === 'request') count++;
      });
    }
    return count;
  }, [bodyTraceSelection, tenoviSelection, bt004, bt105, smig, tenoviDeviceTypes]);
  
  useEffect(() => {
    const requestedDevices = countRequestedDevices();
    setIsRequestLimitExceeded(requestedDevices > 3);
  }, [bt004, bt105, smig, tenoviDeviceTypes, bodyTraceSelection, tenoviSelection, setIsRequestLimitExceeded, countRequestedDevices]);

  let isConnecting = Object.keys(tenoviDeviceTypes).map(
    (item) => tenoviDeviceTypes[item].state,
  );

  return (
    <>
      <Message info>
        <p>You can request up to 3 devices for enrollment.</p>
      </Message>
      <b>Request or Connect Devices</b>
      <Form.Group widths="equal" style={{ marginTop: 5 }}>
        <Form.Checkbox
          label="Tenovi Devices"
          checked={tenoviSelection}
          value="tenovi"
          onChange={() => setTenoviSelection(!tenoviSelection)}
        />
        <Form.Checkbox
          label="Body Trace and Smart Meter"
          checked={bodyTraceSelection}
          value="bodyTrace"
          onChange={() => setBodyTraceSelection(!bodyTraceSelection)}
        />
      </Form.Group>
      {tenoviSelection && (
        <>
          <b>Tenovi Device Connections:</b> <br />{' '}
          {isConnecting.includes('connect') && (
            <Form.Group>
              <Form.Input
                error={formError}
                fluid
                name="tenoviGatewayId"
                maxLength={12}
                label={
                  'Gateway ID: (12 characters, alphanumeric. No dashes)'
                }
                placeholder={'A1B234CD56EF'}
                value={tenoviGatewayId}
                onBlur={(e) => {
                  if (
                    !checkAlphaNumbericRegex.test(tenoviGatewayId) ||
                    tenoviGatewayId.length !== 12
                  ) {
                    setFormError(true);
                  } else {
                    setFormError(false);
                  }
                }}
                onChange={(e) => {
                  const newText = e.target.value;
                  const onlyNumbersAndLetters = newText.replace(
                    /[^A-Za-z0-9]/g,
                    '',
                  );
                  const allCap = onlyNumbersAndLetters.toUpperCase();
                  setTenoviGatewayId(allCap);
                }}
                style={{ width: '150px' }}
              />
            </Form.Group>
          )}
          <TenoviWeightScale
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
          <TenoviBloodPressure
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
          <TenoviBloodPressureXL
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
          <TenoviGlucometer
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
        </>
      )}
      {bodyTraceSelection && (
        <>
          <b>Body Trace and Smart Meter Device Connections:</b> <br />{' '}
          <WeightScaleDevice
            bt004={bt004}
            bt004Imei={bt004Imei}
            setBt004={setBt004}
            setBt004Imei={setBt004Imei}
            setSendEnrollmentText={setSendEnrollmentText}
          />
          <BloodPressureDevice
            bt105={bt105}
            bt105Imei={bt105Imei}
            setBt105={setBt105}
            setBt105Imei={setBt105Imei}
            setSendEnrollmentText={setSendEnrollmentText}
          />
          <BloodGlucoseDevice
            smig={smig}
            smigImei={smigImei}
            setSmig={setSmig}
            setSmigImei={setSmigImei}
            setSendEnrollmentText={setSendEnrollmentText}
          />
        </>
      )}
    </>
  );
};

export default DevicesSection;
